import React from "react"
import TopLayout from "./top-layout"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const WrapWithTheme = ({ element }) => {  

  if (!process.env.NODE_ENV || !process.env.NODE_ENV === "development" || process.env.GATSBY_RECAPTCHA_DISABLE === "true") { 
    console.log('testing') 
    return <TopLayout>{element}</TopLayout>
  } else {
    console.log('testing ', process.env.GATSBY_RECAPTCH_SITE_KEY)
    return (
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={process.env.GATSBY_RECAPTCH_SITE_KEY}
        // scriptProps={{ async: true, defer: true, appendTo: "body" }}
        scriptProps={{
          async: true, // optional, default to false,
          defer: true, // optional, default to false
          appendTo: 'body', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
        // container={{ // optional to render inside custom element
        //   element: "[required_id_or_htmlelement]",
        //   parameters: {
        //     badge: '[inline|bottomright|bottomleft]', // optional, default undefined
        //     theme: 'dark', // optional, default undefined
        //   }
        // }}
      >
        <TopLayout>{element}</TopLayout>
      </GoogleReCaptchaProvider>
    )
  }
}

export default WrapWithTheme
